<template>
  <div class="actions">
    <router-link
      :to="{name: 'declaration-deces-detail', params: {uid: object.uid}}"
      class="btn btn-secondary btn-sm"
    >
      <i class="icofont icofont-eye" />
    </router-link>
      
    <a
      class="btn btn-third btn-sm"
      @click.prevent="deleting"
      v-if="canDelete"
    ><i class="icofont icofont-close" /></a>
  </div>
</template>
  
  <script>
  import {mapGetters} from 'vuex'
  import { CANCEL_DECLARATION_DECES } from '../../../graphql/identification';
  export default {
      props : {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      methods: {
          deleting(){
              this.$apollo.mutate({
                  mutation: CANCEL_DECLARATION_DECES,
                  variables: {
                      "uid": this.object.uid
                  },
                  update: (data) => {
                      if(data) console.log(`Declaration deces ${this.object.uid} deleted successfully`)
                      else console.log(`Error in deletting declaration deces ${this.object.uid}`)
                  }
                })
          }
      },
      computed: {
          ...mapGetters({
              is_super_admin: 'auth/is_super_admin',
              is_admin: 'auth/is_admin',
              is_veterinaire: 'auth/is_veterinaire',
              is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
              is_assistant_veto_major: 'auth/is_assistant_veto_major',
              is_labo_user: 'auth/is_labo_user',
              is_technicien_imagerie: 'auth/is_technicien_imagerie'
          }),
          canUpdate(){
            return this.is_super_admin || this.is_veterinaire
          },
          canDelete(){
            return this.is_admin || this.is_veterinaire
          }
      }
  
  }
  </script>
  
  <style>
  
  </style>